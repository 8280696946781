.styled-table th,
.styled-table td {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.styled-table .clickable {
    user-select: auto;
    cursor: pointer;
}
