.scrollable-listbox {
    overflow-y: auto;
    /*max-height: 400px;*/
    height: 100%;
    /* Adjust this value as needed */
}


.flex-content {
    flex-grow: 1;
    position: relative;
    flex: 1;
    width: 100%;
    height: 100%;
}

.scrollable-content-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: auto;
    display: flex;
    flex-direction: column;
}

tbody tr:nth-child(even) {
    background-color: #f0f0f0;
    /* or any grey color of your choice */
}

tbody tr:nth-child(odd) {
    background-color: #ffffff;
    /* white color */
}

tbody tr td:not(:first-child) {
    text-align: center;
}
