/* LoadingAnimation.css */
.loading-container {
    transition: opacity 0.5s ease-out;
}

.loading-container.loaded {
    opacity: 0;
    pointer-events: none;
}

.loading-logo {
    width: 100px;
    height: 100px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
