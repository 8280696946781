.styled-table {
    border-collapse: collapse;
    width: 90%;
}

.styled-table th,
.styled-table td {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

.styled-table th {
    background-color: #f2f2f2;
    position: sticky;
    top: 0;
    z-index: 1;
    /* Ensures the header stays above other content */
}

.styled-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

/* Make text non-selectable */
.styled-table th,
.styled-table td {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}
